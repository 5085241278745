$size: 50px;

.track {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: $size;
    border-bottom: 1px solid #ddd;
    position: relative;

    .track-play-button {
        flex: 0 0 $size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden; 
        position: absolute;
        left: 0;
        top: 0;
        height: $size;
        width: $size;
        z-index: 8;
    }

    .track-picture-container {
        flex: 0 0 $size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden; 
        margin-right: 10px;
        background-color: #333;

        span {
            display: block;
            width: $size;
            height: $size;
            object-fit: cover;
            opacity: .5;
            background-size: cover;
        }
    }

    .track-info {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.2;
        overflow: hidden;
        padding-right: 10px;

        h5 {
            margin: 0;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }

    &.current {
        background-color: #ddd;

        .track-info {
            h5 {
                font-weight: bold;
            }
        }
        .track-picture-container {
            span {
                opacity: 1;
            }
        }
    }
}