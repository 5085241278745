.app-page.app-page-main {
    padding-top: 10vh;
    overflow: hidden;

    .page-content {
        height: 100%;
    }

    .am-tabs-tab-bar-wrap {
        display: none;
    }
}