$speed: .5s;

.splash-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 10vh;
    z-index: 9;
    transition: height $speed ease;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .header-background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: .2;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) no-repeat center center;
        background-size: cover;
        transition: background-image .2s linear;
    }

    & > * {
        position: relative;
        z-index: 2;
    }

    .loading-overlay {
        opacity: 0;
        transform: translateY(7vh);
        height: auto;
        flex: 0 0 auto;
        overflow: hidden;
    }

    .header-children-container,
    .header-logo-container {
        transition: opacity $speed ease, transform $speed ease ;
        color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0 5vw;
    }

    .header-logo-container {
        opacity: 1;
        transform: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .header-children-container {
        opacity: 0;
        transform: translateY(100%);

        .ant-btn {
            .anticon {
                color: #fff;
            }

            &:first-child {
                margin: 0 5vw 0 -2vw;
            }
            
            &:last-child {
                margin: 0;
            }

            &.offset {
                margin-left: auto;
            }
        }
    }

    &.with-children {
        .header-logo-container {
            opacity: 0;
            transform: translateY(-100%);
        }
        .header-children-container {
            opacity: 1;
            transform: none;

            &.twin {
                opacity: 0;
                transform: translateY(-100%);
            }
        }

        &.change-children {
            .header-children-container {
                transform: translateY(-100%);
                opacity: 0;

                &.twin {
                    transform: none;
                    opacity: 1;
                }
            }
        }

        &.change-silent {
            .header-children-container {
                transition: none;
            }
        }
    }

    &.full-height {
        height: 100vh;

        .loading-overlay {
            opacity: 1;
        }
    }
}