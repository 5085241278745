.playlist {
    .editor-header {
        padding: 2vh;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        .editor-image-uploader {
            flex: 0 0 auto;
            font-size: 0;
            background: no-repeat center center;
            background-size: cover;
            margin-right: 4vw;
            background-color: #ddd;

            .ant-upload-picture-card-wrapper {
                background-color: rgba(0,0,0,.1);

                .ant-upload {
                    background-color: transparent;
                    color: #fff;
                }
            }
            
            .ant-upload.ant-upload-select-picture-card {
                margin: 0;
            }
        }

        .editor-form {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;

            hr {
                border: none;
                height: 1px;
                background-color: #ddd;
                margin: 2vh 0;
            }
        }
    }
}