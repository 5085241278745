.loading-overlay {
    opacity: 0;
    height: 0;
    flex: 0 0 0;
    transition: opacity .2s ease, height .2s ease, flex .2s ease;
    overflow: hidden;

    &.visible {
        opacity: 1;
        height: 100%;
        flex: 1 1 100%;
    }
}