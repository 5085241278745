.playlist-item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10%;
    position: relative;

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    &.current {
        animation: toggle .2s ease 1;
    }

    .playlist-image-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3vh;
        font-weight: 100;
        text-align: center;
        height: 100%;
        background-color: #ddd;
    }

    img + .playlist-image-placeholder {
        position: absolute;
        left: 10%;
        top: 10%;
        width: 80%;
        height: 80%;
        background-color: rgba(0,0,0,.2);
        color: #fff;
    }
}

@keyframes toggle {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}