$size: 50px;
$speed: .5s;
$search: 6px;

.player {
    height: 0;
    transition: height $speed ease, transform $speed ease;
    background-color: #111;
    color: #fff;
    position: relative;
    text-align: center;
    transform: translateY(2vh);

    * {
        position: relative;
        z-index: 2;
    }

    .player-header {
        position: static;
        padding-top: 7vh;

        .track-info {
            transition: opacity $speed ease;
        }

        h5 {
            color: #fff;
            margin: 0;
        }
    }

    .track-picture-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        
        span {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .2;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) no-repeat center center;
            background-size: cover;
            transition: background-image .5s ease;
        }
    }

    .player-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        line-height: $size;
        transition: opacity $speed ease;

        & > * {
            flex: 0 0 $size;
            height: $size;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            &.player-control-side {
                opacity: .5;

                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%) scale(0);
                    transition: transform .2s ease;
                    will-change: transform;
                    background-color: rgba( 255, 255, 255, .2 );
                    width: 50%;
                    height: 50%;
                    border-radius: 50%;
                }

                &:first-child {
                    margin-right: auto;
                }
                &:last-child {
                    margin-left: auto;
                }

                &.active {
                    opacity: 1;

                    &:before {
                        transform: translate(-50%,-50%) scale(1);
                    }
                }
            }
        }
    }

    .player-search {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 0;
        transition: height $speed ease;
        transform: translateY(-50%);

        .player-search-bar,
        .player-search-loaded {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: $search;
            background-color: rgba( 255, 255, 255, .5 );
            pointer-events: none;
            z-index: 2;
            border: 1px solid #999;
        }

        .player-search-bar,
        .player-search-dot {
            box-shadow: 0px 0px 10px rgba(0,0,0,.2);
            border: 1px solid #999;
        }

        .player-search-loaded {
            width: 0;
            background-color: rgba( 255, 255, 255, .8 );
        }

        .player-search-dot {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%,-30%);
            width: $search * 3;
            height: $search * 3;
            background-color: #fff;
            border-radius: $search * 3 / 2;
            pointer-events: none;
            z-index: 3;
            will-change: left;
        }
    }

    &.visible {
        height: 25vh;
        transform: none;

        .player-search {
            height: 5vh;
        }

        &.resizing {
            height: 10vh;

            .player-header .track-info,
            .player-controls {
                opacity: 0;
            }
        }

        &.small {
            height: 10vh;

            &.resizing {
                height: 25vh;
            }

            .player-header {
                padding-top: 2.5vh;
            }
            
            .track-info {
                padding: 0 2vh;
                text-align: left;
                font-size: 12px;
                width: 50%;
                white-space: nowrap;

                & > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                small {
                    opacity: .5;
                }
            }

            .player-controls {
                position: absolute;
                right: 0;
                top: 1vh;
                transform: none;
                width: $size * 3;

                .player-control-side {
                    display: none;
                }
            }
        }
    }
}