.track-item-selection {
    position: relative;

    .track-item-selector-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-left: none;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ant-checkbox-inner {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            border-width: .5vh;

            &:after {
                left: 30%;
            }
        }
    }
}