.playlists {
    .playlists-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;

        & > * {
            flex: 0 0 50%;
            height: 50vw;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .playlist-item.as-button {
        .playlist-image-placeholder {
            font-size: 64px;
            color: #ccc;
            background-color: transparent;
            border: .5vh solid #ddd;
        }
    }
}